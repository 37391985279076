<template>
	<div class="product-detail">
		<div class="yc-detail" v-if="productInfo.type==0">
			<div class="edit-header">
				<div class="tc">
					<div class="code">{{productInfo.dep}}</div>
					<div>始发港</div>
				</div>
				<div class="tc">
					<div class="name air-code">{{productInfo.airline}}</div>
					<div class="center">
						<div class="dashed"></div>
						<div class="transit" v-if="!!productInfo.transit && productInfo.transit!='undefined'">中转</div>
						<div class="transit" v-else>直飞</div>
						<div class="dashed"></div>
					</div>
					<div class="transit-name" v-if="!!productInfo.transit && productInfo.transit!='undefined'">{{productInfo.transit}}</div>
				</div>
				<div class="tc">
					<div class="code">{{productInfo.des}}</div>
					<div>目的港</div>
				</div>
			</div>
			<div class="edit-content">
				<van-cell-group :border="false">
					<van-cell title="产品名称" :value="productInfo.title" :border="false" />
					<van-cell title="售卖时间" class="picker-cell" :border="false">
						<div class="date-picker">
							<div >{{productInfo.et}}</div>
							<div class="mini-line">-</div>
							<div>{{productInfo.st}}</div>
						</div>
					</van-cell>
					<van-cell title="运价" :class="productInfo.price_type==1 ? 'price-list':''" :border="false">
						<div class="price-input tr" v-if="productInfo.price_type==1">
							<div class="input tc">
								<div class="c9">+45</div>
								<div class="c3 height">{{ productInfo.q1>0 ? productInfo.q1 : '-'}}</div>
							</div>
							<div class="input tc">
								<div class="c9">+100</div>
								<div class="c3 height">{{ productInfo.q2>0 ? productInfo.q2 : '-'}}</div>
							</div>
							<div class="input tc">
								<div class="c9">+300</div>
								<div class="c3 height">{{ productInfo.q3>0 ? productInfo.q3 : '-' }}</div>
							</div>
							<div class="input tc">
								<div class="c9">+500</div>
								<div class="c3 height">{{ productInfo.q4>0 ? productInfo.q4 : '-' }}</div>
							</div>
							<div class="input tc">
								<div class="c9">+1000</div>
								<div class="c3 height">{{ productInfo.q5>0 ? productInfo.q5 : '-' }}</div>
							</div>
							<div class="input tc">
								<div class="c9">密度</div>
								<div class="c3 height">{{ productInfo.density }}</div>
							</div>
						</div>
						<div class="red-color" v-else>{{ productInfo.price_remark }}</div>
					</van-cell>
				</van-cell-group>
			</div>
			<div class="shop-msg">
				<div class="shop-msg-top">
					<div class="shop-msg-left">
						<van-image
							round
							width="0.94rem"
							height="0.94rem"
							:src="!!agentInfo.avatar? 'http://' + agentInfo.avatar:'https://doc.yctop.com/weapp/zjhy/static/2021-1-26/avatar-h5.png'"
							@click="toShopHome"
						/>
						<div class="msg-title">
							<p>{{agentInfo.name}}</p>
							<p class="fans">
								<span class="fans-name">粉丝数</span>
								<span>{{agentInfo.fanCnt}}</span>
							</p>
						</div>
					</div>
					<div class="shop-msg-right">
						<van-button :class="agentInfo.favored ? 'favor-btn favored':'favor-btn no-attention'" @click="favorChange(agentInfo.favored)">
							{{agentInfo.favored ? '已关注':'关注'}}
                        </van-button>
						<van-button class="favor-btn to-shop" @click="toShopHome">
							进店
                        </van-button>
					</div>
				</div>
				<div class="shop-msg-bottom" v-if="agentInfo.company_title && !!agentInfo.company_title">
					<van-icon class-prefix="iconqiye iconfont" />
					{{agentInfo.company_title}}
				</div>
			</div>
			<van-tabs class="detail-tabs" :swipeable="true" title-active-color="#FF5226" title-inactive-color="#303133" line-width="1.28rem" line-height="0.04rem">
				<van-tab title="航班信息" name="a" title-style="font-size: 0.32rem; ">
					<div class="tab-first" style="margin-top:0.2rem;">
						<div class="table">
							<div class="table-tr bd-bottom c6">
								<div class="table-th">航班号</div>
								<div class="table-th">起运</div>
								<div class="table-th">到达</div>
								<div class="table-th">航班班次</div>
								<div class="table-th bd-none">机型</div>
							</div>
							<div class="table-tr c3"  v-for="unit in flightList"  :key="unit.name">
								<div class="table-td">{{unit.flightNo}}</div>
								<div class="table-td">{{unit.etd}}</div>
								<div class="table-td">{{unit.eta}}</div>
								<div class="table-td">{{unit.flightPlan}}</div>
								<div class="table-td bd-none">{{unit.flightModel}}</div>
							</div>
						</div>
					</div>
					<div class="placeholder"></div>
				</van-tab>
				<van-tab title="航司转运" name="b" title-style="font-size: 0.32rem;">
					<div class="tab-first c3" style="margin-top:0.2rem;">
						<div class="table-header bd-bottom tc">
							<div class="text-l">区域</div>
							<div class="text-r">公斤/价格（CNY）</div>
						</div>
						<div class="table">
							<div class="table-tr c9">
								<div class="table-th text-l"></div>
								<div class="table-th bd-none">+45</div>
								<div class="table-th bd-none">+100</div>
								<div class="table-th bd-none">+300</div>
								<div class="table-th bd-none">+500</div>
								<div class="table-th bd-none">+1000</div>
							</div>
							<div class="table-tr" v-for="(item,index) in transitList" :key="index">
								<div class="table-td c9 text-l">{{item.destination}}</div>
								<div class="table-td bd-none">{{item.q1Price}}</div>
								<div class="table-td bd-none">{{item.q2Price}}</div>
								<div class="table-td bd-none">{{item.q3Price}}</div>
								<div class="table-td bd-none">{{item.q4Price}}</div>
								<div class="table-td bd-none">{{item.q5Price}}</div>
							</div>
						</div>
					</div>
					<div class="placeholder"></div>
				</van-tab>
				<van-tab title="其他说明" name="c" title-style="font-size: 0.32rem;">
					<van-cell-group :border="false" style="margin:0.2rem 0;padding:0.15rem 0 0.3rem">
						<van-cell title="区域" :value="priceInfo.domainName" :border="false" class="price-cell" center />
						<van-cell title="费用说明" :value="priceInfo.costDescription" :border="false" class="price-cell" center />
						<!-- <van-cell title="是否分泡" :value="priceInfo.transportType" :border="false" /> -->
						<van-cell title="尺寸限制说明" :value="priceInfo.sizeTopLine" :border="false" class="price-cell" center />
						<van-cell title="单件重量限制" :value="priceInfo.singleProductWeight" :border="false" class="price-cell" center />
						<van-cell title="最低承运重量" :value="priceInfo.weightBottomLine" :border="false" class="price-cell" center />
						<van-cell title="锂电池说明" :value="priceInfo.lithiumType" :border="false" class="price-cell" center />
						<van-cell title="转运信息" :value="priceInfo.transportType" :border="false" class="price-cell" center />
					</van-cell-group>
					<div class="placeholder"></div>
				</van-tab>
			</van-tabs>
		</div>
		<div class="custom-create" v-else>
			<div class="create-detail">
				<div class="custom-title">基本信息</div>
				<div class="border">
					<van-cell title="产品名称" center :value="productInfo.title" class="title-input" :border="false" title-style="flex:0.5"/>
				</div>
				<div class="port-input">
					<div class="input-side">
						<div class="name">始发港</div>
						<div class="code">{{productInfo.dep}}</div>
					</div>
					<div class="input-line"></div>
					<div class="input-side">
						<div class="name">目的港</div>
						<div class="code">{{productInfo.des}}</div>
					</div>
				</div>
				<div class="port-input">
					<div class="input-side">
						<div class="name">航司</div>
						<div class="code">{{productInfo.airline}}</div>
					</div>
					<div class="input-line"></div>
					<div class="input-side">
						<div class="name">中转港</div>
						<div class="code">{{productInfo.transit}}</div>
					</div>
				</div>
				<div class="border">
					<van-cell title="售卖时间" class="picker-cell" :border="false" center>
						<div class="date-picker">
							<div >{{productInfo.et}}</div>
							<div class="mini-line"></div>
							<div>{{productInfo.st}}</div>
						</div>
					</van-cell>
				</div>
				<van-cell title="运价" class="price-list" :border="false">
					<div class="price-input tr" v-if="productInfo.price_type==1">
						<div class="input tc">
							<div class="c9">+45</div>
							<div class="c3 height">{{ productInfo.q1>0 ? productInfo.q1 : '-'}}</div>
						</div>
						<div class="input tc">
							<div class="c9">+100</div>
							<div class="c3 height">{{ productInfo.q2>0 ? productInfo.q2 : '-'}}</div>
						</div>
						<div class="input tc">
							<div class="c9">+300</div>
							<div class="c3 height">{{ productInfo.q3>0 ? productInfo.q3 : '-' }}</div>
						</div>
						<div class="input tc">
							<div class="c9">+500</div>
							<div class="c3 height">{{ productInfo.q4>0 ? productInfo.q4 : '-' }}</div>
						</div>
						<div class="input tc">
							<div class="c9">+1000</div>
							<div class="c3 height">{{ productInfo.q5>0 ? productInfo.q5 : '-' }}</div>
						</div>
						<div class="input tc">
							<div class="c9">密度</div>
							<div class="c3 height">{{ productInfo.density }}</div>
						</div>
					</div>
					<div class="red-color" v-else>{{ productInfo.price_remark }}</div>
				</van-cell>
			</div>
			<div class="create-detail">
				<div class="custom-title">航班信息</div>
				<div class="port-input">
					<div class="input-side flight">
						<div class="flight-name">航班号</div>
						<div class="code">{{!!productInfo.flight_no?productInfo.flight_no:''}}</div>
					</div>
					<div class="input-line"></div>
					<div class="input-side flight">
						<div class="flight-name">机型</div>
						<div class="code">{{!!productInfo.flight_type?productInfo.flight_type:''}}</div>
					</div>
				</div>
				<div class="flight-plan">
					<div class="plan-title">航班班次</div>
					<div class="plan-detail">
						<div :class="item.selected ? 'selected date':'date' " v-for="(item,index) in dateArray" :key="index">{{item.date}}</div>
					</div>
				</div>
			</div>
			<div class="shop-msg">
				<div class="shop-msg-top">
					<div class="shop-msg-left">
						<van-image
							round
							width="0.94rem"
							height="0.94rem"
							:src="!!agentInfo.avatar? 'http://' + agentInfo.avatar:'https://doc.yctop.com/weapp/zjhy/static/2021-1-26/avatar-h5.png'"
							@click="toShopHome"
						/>
						<div class="msg-title">
							<p>{{agentInfo.name}}</p>
							<p class="fans">
								<span class="fans-name">粉丝数</span>
								<span>{{agentInfo.fanCnt}}</span>
							</p>
						</div>
					</div>
					<div class="shop-msg-right">
						<van-button :class="agentInfo.favored ? 'favor-btn favored':'favor-btn no-attention'" @click="favorChange(agentInfo.favored)">
							{{agentInfo.favored ? '已关注':'关注'}}
                        </van-button>
						<van-button class="favor-btn to-shop" @click="toShopHome">
							进店
                        </van-button>
					</div>
				</div>
				<div class="shop-msg-bottom" v-if="agentInfo.company_title && !!agentInfo.company_title">
					<van-icon class-prefix="iconqiye iconfont" />
					{{agentInfo.company_title}}
				</div>
			</div>
			<div class="placeholder"></div>
		</div>
		<div class="detail-bottom">
			<van-button class="bottom-btn bottom-call" @click="toShopHome">
				<van-image
					width="0.32rem"
					height="0.32rem"
					src="https://doc.yctop.com/weapp/zjhy/static/2021-1-21/shop.png"
				/>
				<p class="c6">店铺</p>
			</van-button>
			<div>
				<a :href="agentContact ? ('tel:' + agentContact) : '#'" v-if="isCustom">
					<van-button class="bottom-btn bottom-call" >
						<van-image
							width="0.29rem"
							height="0.28rem"
							src="https://doc.yctop.com/weapp/zjhy/static/2021-1-21/phone.png"
						/>
						<p class="c6">电话</p>
					</van-button>
				</a>
				<van-button class="bottom-btn bottom-call" v-else @click="toBindMobile">
					<van-image
						width="0.29rem"
						height="0.28rem"
						src="https://doc.yctop.com/weapp/zjhy/static/2021-1-21/phone.png"
					/>
					<p class="c6">电话</p>
				</van-button>
			</div>
			<van-button class="bottom-btn bottom-create" @click="createPreOrder">询最低价</van-button>
		</div>
	</div>
</template>
<script>
	/* eslint-disable */
	import { mapState, mapGetters } from 'vuex';
	import { Dialog } from 'vant';
	import  REQ_HANDLE from '@/utils/request.js';
	import UTILS from '@/utils/common-utils';
	export default {
		data: ()=> ({
			productId: null,
			productInfo: {},
			flightList:[],
			transitList:[],
			priceInfo:'',
			shopId:'',
			agentContact:"",
            dateArray:[
                {date:'一',selected:false},
                {date:'二',selected:false},
                {date:'三',selected:false},
                {date:'四',selected:false},
                {date:'五',selected:false},
                {date:'六',selected:false},
                {date:'日',selected:false},
			],
			agentInfo: {
				avatar: '',
				name: '',
				company_title:'',
				fanCnt:0,
				favored:false,
			},
		}),
		computed: {
			...mapState({
				usr: state => state.usr,
				token: state => state.token,
				wx_usr: state => state.wx_usr,
				mall_usr: state => state.mall_usr
			}),
			...mapGetters({
				login: 'checkLogin',
				isCustom: 'isCustom',
			})
		},
		created: async function() {
			this.productId = this.$route.params.productId
			this.shopId=this.$route.params.shopId ?this.$route.params.shopId:''
			!!this.shopId && (this.agentInfo=await this.$store.dispatch('getAgentInfo',{shopId:this.shopId}) )
		},
		mounted: async function() {
			await this.loadProductInfo()
			this.productInfo.type==0 && await this.loadOtherInfo()
			let title= this.productInfo.dep + '-' + this.productInfo.des
			!!this.shopId && await this.$store.dispatch('getAgentName', {shopId:this.shopId,title:title})
			this.agentContact=await UTILS.getAgentContact(this.shopId)
			
		},
		methods: {
			createPreOrder: function() {
				if(this.isCustom) {
					let params = this.productInfo || {}
					params.shopId=  this.$route.params.shopId
					this.$router.push({ name: 'CreatePreOrderPage', params: params})
				} else {
					Dialog.confirm({
						title: '提示',
						message: '为了方便卖家能与您及时沟通，请验证手机号!',
					})
					.then(() => {
						this.$router.push('/bind-mobile')
					})
					.catch(() => {
						// on cancel
					});
				}
			},
			favorChange: async function(favored){
				favored==true ? this.cancelFavored() : await this.isFavored()
			},
			isFavored: async function(){
				let url = '/api-mall/favor',
					_this=this,
					params={
						agent_id: this.shopId
					},
					res = await REQ_HANDLE.agent.$post(url, params)
				if(!!res){
					_this.agentInfo=await _this.$store.dispatch('getAgentInfo',{shopId:_this.shopId})
				}
			},
			cancelFavored: function(){
				let _this=this
				Dialog.confirm({
					message: '确认取消关注？',
					confirmButtonText:'取消关注',
					cancelButtonText:'再想想',
				})
				.then( async () => {
					let url = '/api-mall/favor/' + _this.shopId,
					res = await REQ_HANDLE.agent.$delete(url)
					if(!!res){
						_this.agentInfo=await _this.$store.dispatch('getAgentInfo',{shopId:_this.shopId})
					}
				})
				.catch(() => {
					// on cancel
				});
			},
			loadProductInfo: async function() {
				let url = '/api-mall/product/' + this.productId
				let res = await REQ_HANDLE.agent.$get(url)
				if(!!res) {
					this.productInfo = Object.assign({}, this.productInfo, res.data)
				}
				if(!!res.data.flight_plan){
					let dbFlightPlan = res.data.flight_plan || ''
					for (const char of dbFlightPlan) {
						let idx = parseInt(char)
						!!idx  && (this.dateArray[idx - 1].selected = true)
					}
				}
				if(this.productInfo.type==0 && !!this.productInfo.route && this.productInfo.route!='undefined' && !this.productInfo.transit){
					if (this.productInfo.route.length <=8 && this.productInfo.dep==this.productInfo.route.substring(0,3) && this.productInfo.des==this.productInfo.route.substring(this.productInfo.route.length-3,this.productInfo.route.length) ){
						this.productInfo.transit=''
					}else{
						this.productInfo.transit=this.productInfo.route.substring(this.productInfo.route.length-7,this.productInfo.route.length-4)
					}
				}
				
			},
			loadOtherInfo: async function(){
				let url="/api-mall/proxy/yctop/flights/list",
					transitUrl="/api-mall/proxy/yctop/transit/list",
					priceUrl="/api-mall/proxy/yctop/price/list",
					that= this,
					params = {
						freightRoute: that.productInfo.route,
						productId: that.productInfo.id_at_yctop,
					},
					transitParams = {
						destination: that.productInfo.des,
						productId:  that.productInfo.id_at_yctop,
					}
				let res = await REQ_HANDLE.agent.$get(url, params)
				if(!!res) {
					res.data.data.first && (that.flightList=that.flightList.concat(res.data.data.first))
					res.data.data.second && (that.flightList=that.flightList.concat(res.data.data.second))
					res.data.data.third && (that.flightList=that.flightList.concat(res.data.data.third))
				}
				let result = await REQ_HANDLE.agent.$get(transitUrl, transitParams)
				if(!!result) {
					that.transitList = result.data.data.transitList
				}
				let resPrice = await REQ_HANDLE.agent.$get(priceUrl, params)
				if(!!resPrice) {
					that.priceInfo = Object.assign({}, that.priceInfo, resPrice.data.data.priceInfo)
				}
			},
			toBindMobile: function(){
				Dialog.confirm({
					title: '提示',
					message: '为了方便卖家能与您及时沟通，请验证手机号!',
				})
				.then(() => {
					this.$router.push('/bind-mobile')
				})
				.catch(() => {
					// on cancel
				});
			},
			toShopHome:function(){
				!!this.shopId && this.$router.push('/shop/'+this.shopId)
			},

		}
	};
</script>
<style scoped >
@import "~@/assets/css/productDetail.css";
</style>